import React from 'react'
import Header from '../partials/Header'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

export default function MdxTemplate({
  data: {
    mdx: {
      frontmatter: { date, title },
      body,
    },
  },
  ...props
}) {

  return (
    <div className="">
      <Header />
      <article className="container px-4 mx-auto">
        <header>
          <time dateTime={date}>{date}</time>
          <h1>{title}</h1>
        </header>
        <div className="max-w-3xl">
          <MDXRenderer>{body}</MDXRenderer>
        </div>
      </article>
    </div>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { path: { eq: $slug } }) {
      id
      body
      frontmatter {
        date
        title
      }
    }
  }
`
